/* eslint-disable */
import * as serviceWorker from "./serviceWorker";
import React from "react";
import ReactDOM from "react-dom";



const root = document.getElementById("root");

if (location.pathname.includes("/")) {
	import("Admin").then(({default: Admin}) => ReactDOM.render((<React.StrictMode><Admin /></React.StrictMode>), root));
}


else if (location.pathname.includes("/admin")) {
	import("Admin").then(({default: Admin}) => ReactDOM.render((<React.StrictMode><Admin /></React.StrictMode>), root));
} 
// else if (location.pathname.includes("/analytics")) {
// 	import("Analytics").then(({default: Analytics}) => ReactDOM.render((<React.StrictMode><Analytics /></React.StrictMode>), root));
// }
// else if (location.pathname.includes("/login")) {
// 	import("Login").then(({default: Login}) => ReactDOM.render((<React.StrictMode><Login /></React.StrictMode>), root));
// }
// else if (location.pathname.includes("/register")) {
// 	import("Login/Register").then(({default: Register}) => ReactDOM.render((<React.StrictMode><Register /></React.StrictMode>), root));
// }
// else if (location.pathname.includes("/my-page")) {
// 	import("MyPage").then(({default: MyPage}) => ReactDOM.render((<React.StrictMode><MyPage /></React.StrictMode>), root));
// }
// else if (location.pathname.includes("/editpage")) {
// 	import("MyPage/EditPage").then(({default: EditPage}) => ReactDOM.render((<React.StrictMode><EditPage /></React.StrictMode>), root));
// }
// else if (location.pathname.includes("/passwordeditpage")) {
// 	import("MyPage/PasswordEditPage").then(({default: PasswordEditPage}) => ReactDOM.render((<React.StrictMode><PasswordEditPage /></React.StrictMode>), root));
// }
//
// else if (location.pathname.includes("/users")) {
// 	import("Admin/pages/Users").then(({default: Users}) => ReactDOM.render((<React.StrictMode><Users /></React.StrictMode>), root));
// }


serviceWorker.unregister();
